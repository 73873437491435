import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Skeleton,
    TextareaAutosize,
    Tooltip,
    Typography
} from "@mui/material";
import {Line, LineChart, ResponsiveContainer} from "recharts";
import {useEffect, useMemo, useRef, useState} from "react";
import {useGetDiagramData} from "../custom-hooks/uiEnhancers/Dashboards/DiagramHelper/useGetDiagramData";
import {TimeSeriesData, TimeSeriesEntry} from "../custom-hooks/uiEnhancers/Dashboards/types/type";


interface point {
    x: number,
    y: number
}

interface KPICardProps {
    width: any,
    label: string,
    kpiName: string
    timeSeriesData: TimeSeriesEntry[]
    latestValue: {
        value: string | number,
        timestamp: string
        unit: string
    }
    loading: boolean
    maxHeight?: string
    maxWidth?: string,
    onClick?: ( event: any) => void
}


export const KPICard = (props: KPICardProps) => {


    const ref = useRef(null)
    const [parentHeight, setParentHeight] = useState(0)
    const parentRef = useRef(null)
    const isNumber: boolean = useMemo(() => !isNaN(parseInt(`${props.latestValue.value}`)), [props.latestValue.value])
    //@ts-ignore
    const formattedValue: string = useMemo(() => props.latestValue.unit && isNumber ? `${parseFloat(props.latestValue.value).toFixed(2)} ${props.latestValue.unit}` : isNumber ? parseFloat(props.latestValue.value).toFixed(2) : props.latestValue.value, [props.latestValue.value, props.latestValue.unit])

    useEffect(() => {

        if (parentRef) {
            //@ts-ignore
            setParentHeight((parentRef?.current?.clientWidth * 0.56) || 100)

        }
    }, [parentRef, props])

    return <Paper key={`KPICard-${props.kpiName}`} sx={{
        width: props.width,
        minHeight: "75px",
        maxHeight: props.maxHeight || "100px",
        minWidth: "125px",
        maxWidth: props.maxWidth || "200px",
        height: `${parentHeight}px`,
        padding: "0.5rem",
        cursor: "pointer"
    }}
        onClick={props.onClick}
    >
        <Tooltip title={props.latestValue.timestamp}  followCursor={true} >

            <Box ref={parentRef} key="ContainerBox" width={"100%"} height={"100%"} display={"flex"}
                 flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Box key="ContainerBox-Header" width={"100%"} display={"flex"} height={"5%"} alignItems={"center"}
                     justifyContent={"center"}>
                    <Tooltip title={props.label} placement={"top"}>
                        <Typography noWrap={true} key="KPIName" fontSize={"1rem"}>{props.label}</Typography>
                    </Tooltip>
                </Box>

                <Box key="ContainerBox-KPI" width={"100%"} display={"flex"}
                     height={isNumber && props.timeSeriesData.length > 0 ? "65%" : "95%"} alignItems={"center"}
                     justifyContent={"center"} overflow={"hidden"} padding={"0.25rem"}>
                    {props.loading ? <Skeleton variant={"text"} sx={{width: "100%"}}/> :
                        <Typography sx={{width: "100%"}} align={"center"} noWrap={true} key={"KPI-Value"}
                                    color={isNumber ? props.latestValue.value > 0 ? "green" : "red" : "black"}
                                    fontSize={isNumber ? "1rem" : "0.75rem"}>
                            {formattedValue}
                        </Typography>

                    }

                </Box>
                {

                    (isNumber && props.timeSeriesData.length > 0) ?
                        <Box ref={ref} width={"100%"} display={"flex"} height={"30%"} alignItems={"center"}
                             justifyContent={"center"}>
                            <ResponsiveContainer width="100%" aspect={6}>
                                <LineChart width={500} height={500} data={props.timeSeriesData}>
                                    <Line dot={false} type="monotone" dataKey={props.kpiName}
                                          stroke={isNumber ? props.latestValue.value > 0 ? "green" : "red" : "black"}/>
                                </LineChart>
                            </ResponsiveContainer>

                        </Box> : null

                }

            </Box>
        </Tooltip>

    </Paper>
}