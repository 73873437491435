

import { isPointInPolygon, getDistance } from "geolib";
import {ViewPort} from "../mapState";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {Gateway} from "../../../model/lora-node/gateway";
import * as L from "leaflet";
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import {Router} from "@mui/icons-material";
import "./gatewayStyles.css";
import {GatewayResponseItem, useGetConnectedNodes} from "./useGetConnectedNodes";


export const useGetGatewayMarkerGroup = ( viewport: ViewPort): [ L.MarkerClusterGroup, Gateway|null , ( node:LoraNode ) => boolean , GatewayResponseItem[]] => {

    const gateways:Gateway[]  = useSelector(
        (state: any) => (state["Gateway"]?.items as Gateway[]).filter((gateway: Gateway) =>  !gateway.deleted )
    );

    const [ selectedGateway, setSelectedGateway ] = useState<Gateway | null>(null);
    const nodeList: GatewayResponseItem[] = useGetConnectedNodes(selectedGateway);

    const isNodeConnectedToSelectedGateway = useCallback((node: LoraNode) => {
        return nodeList.findIndex( nodeListItem => node.id === nodeListItem.nodeId) >= 0;
    }, [ nodeList]);

    const [ clusterGroup, setClusterGroup ] = useState<L.MarkerClusterGroup>(L.markerClusterGroup({
        chunkedLoading: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 20,

        maxClusterRadius: (zoom) => {
            return (19 / zoom) * 20;
        },
    }));
    useEffect(() => {

        if(clusterGroup) {
            clusterGroup.clearLayers();
            gateways.filter((gateway: Gateway) =>
                isPointInPolygon({
                    lat: gateway.location.lat,
                    lng: gateway.location.lng
                }, [viewport.topLeft, viewport.topRight, viewport.bottomRight, viewport.bottomLeft])
            ).forEach((gateway: Gateway) => {

                const marker = L.marker(
                    new L.LatLng(gateway.location.lat, gateway.location.lng),
                    {
                        icon: divIcon({
                            html: renderToStaticMarkup(
                                <div className={`markerGateway${ selectedGateway === gateway ? " markerGatewayHighlight":""}`}>
                                    <div
                                        style={{
                                            color: "white",
                                            transform: "rotate(-45deg)",
                                            textDecorationColor: "white",
                                            zIndex: "300",
                                            width: "75%",
                                            height: "75%",
                                            filter:
                                                "invert(0%) sepia(0%) saturate(7468%) hue-rotate(107deg) brightness(109%) contrast(100%)",
                                        }}
                                    >
                                        <Router/>
                                    </div>
                                </div>
                            ),
                            className: "",
                        }),
                    }
                );
                marker.addEventListener({
                    click: (event) => {
                        if(selectedGateway === gateway){
                            setSelectedGateway(null);
                            return;
                        }
                        setSelectedGateway(gateway);

                    }});
                marker.bindPopup(`<b>${gateway.name}</b><br>${gateway.eui}`);
                clusterGroup.addLayer(marker);
            })
        }

    }, [viewport, gateways, clusterGroup]);

    return  [ clusterGroup, selectedGateway , isNodeConnectedToSelectedGateway, nodeList];

}