import {DiagramProps} from "./useGetDiagramType";
import {TimeSeriesEntry} from "../../types/type";

import {ColorPalette} from "./ColorPalette";

const getNodeIdPrefixedEntry = (entry: TimeSeriesEntry): TimeSeriesEntry => {
    const node = entry.nodeId
    const keys = Object.keys(entry)
    const newEntry: any = {}
    keys.forEach(key => {
        if (key === "nodeId" || key === "time") {
            return
        }
        newEntry[`${node}-${key}`] = entry[key]
    })
    newEntry.time = new Date(entry.time).toLocaleString()
    newEntry.nodeId = node
    return newEntry as TimeSeriesEntry

}

export interface NodeColorMap {
    [node: string]: string
}
export function transformToRechartsSeries(props: DiagramProps): [TimeSeriesEntry[] , string[], NodeColorMap]{
    const timeseriesMap = new Map<string, TimeSeriesEntry>()
    const nodes :string[] = []
    const kpis: string[] = []


    props.data.forEach(entry => {
        const key = entry.time
        if (timeseriesMap.has(key)) {
            const existingEntry = timeseriesMap.get(key)
            timeseriesMap.set(key, {...existingEntry, ...getNodeIdPrefixedEntry(entry)})
        } else {
            timeseriesMap.set(key, getNodeIdPrefixedEntry(entry))
        }

        nodes.push(entry.nodeId)
        Object.keys(entry).forEach(key => {
            if (key === "nodeId" || key === "time") {
                return
            }
            kpis.push(key)
        })

    })

    const nodeSet = nodes.filter((value, index, self) => self.indexOf(value) === index)
    const kpiSet = kpis.filter((value, index, self) => self.indexOf(value) === index)
    const nodeColorMap: NodeColorMap = {}
    nodeSet.forEach((node, index) => {
        kpiSet.forEach((kpi, index1) => {
            //@ts-ignore
            nodeColorMap[`${node}-${kpi}`] = ColorPalette[`color${(index + index1) %6}`].color
        })
    })

    return [Array.from(timeseriesMap.values()) , nodeSet, nodeColorMap]

}