import {DiagramProperties, isLastDataProperties, isTimeSeriesProperties, TimeSeriesData} from "../types/type";
import {Box, Button, IconButton, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import "./Diagram.css"
import {CartesianGrid, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";
import {DiagramCard} from "./DiagramCard";
import {Filter1Rounded, FilterAlt} from "@mui/icons-material";
import {useGetDiagramType} from "./DiagramTypes/useGetDiagramType";
import {useGetFirebaseConfigParameter} from "../../../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import axios from "axios";
import {useGetDiagramData} from "../DiagramHelper/useGetDiagramData";


export interface DiagramParams {
    diagramProps: DiagramProperties;
    setDragStatus: (dashboardName: string, dragging: boolean, origin: "preview" | "dashboard" | "config",
                    diagramProperties: DiagramProperties | null, position: {
            mouseX: number, mouseY: number, diagramX: number, diagramY: number
        }) => void;
    positionProps: { posX: number, posY: number, sizeX: number, sizeY: number };
    onDrag: (e: any) => void;

    onDragEnd(x: number, y: number, xBottom?: number, yBottom?: number): void;

    width?: string | number;
    height?: string | number;
    origin: "dashboard" | "preview" | "config";
    resizable?: boolean;
    deleteFromDashboard: () => void;
}

export const Diagram = (props: DiagramParams) => {
    const diagramData = useGetDiagramData( props.diagramProps)

    const KapionDiagram = useGetDiagramType( props.diagramProps.diagramProperties?.diagramType ,{ data: diagramData.data.timeSeries, kpis:props.diagramProps.influxQueryProperties?.values || [], nodes: props.diagramProps.influxQueryProperties?.nodeIds || []})

    return <DiagramCard key={`${props.positionProps.posX}-${props.positionProps.posY}`}{...props}>
        <Box flexGrow={1} overflow={"hidden"}>
        {KapionDiagram}
        </Box>

    </DiagramCard>

}