import {
    Bar,
    BarChart,
    CartesianGrid, Cell,
    Legend,
    Line,
    LineChart, Pie,
    PieChart,
    ResponsiveContainer,
    XAxis,
    YAxis
} from "recharts";
import React, {useEffect, useState} from "react";
import {DiagramProps} from "./useGetDiagramType";
import {Box, IconButton} from "@mui/material";
import {TimeSeriesEntry} from "../../types/type";
import {transformToRechartsSeries} from "./RechartsHelper";
import {CloseFullscreen, Minimize} from "@mui/icons-material";


export const KapionPieChart = (props: DiagramProps) => {

    const [timeSeries, setTimeSeries] = useState<TimeSeriesEntry|null>(null)
    const [nodes, setNodes] = useState<string[]>([])

    useEffect(() => {

            const [series, nodes] = transformToRechartsSeries(props);
            setTimeSeries(series[series.length - 1] || null)
            setNodes(nodes)
    }, [props]);
    const diagramSize=100/props.kpis.length
    return  <Box display={"flex"} flexGrow={1} flexDirection={"column"} position={"relative"}>

        <ResponsiveContainer width={"100%"} height={"100%"}>
                    <PieChart width={1000} height={1000/props.kpis.length} >
                        {props.kpis.map((kpi, outerIndex) => {



                                const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                                if(!timeSeries){
                                    return <></>
                                }
                                const diagramData = Object.keys(timeSeries).filter((key) => key.includes(kpi)).map(key => {
                                    return {name: key, value: timeSeries[key]}
                                })

                                const innerRadius = diagramSize * outerIndex + 2 + "%"
                                const outerRadius = diagramSize * (outerIndex + 1) + "%"
                                console.log("dataKey", `${kpi}`, color, innerRadius, outerRadius, diagramData)


                                return <Pie key={`${kpi}`}
                                            dataKey={`value`}
                                            data={diagramData}
                                            nameKey={"name"}
                                            fill={color}
                                            innerRadius={innerRadius}
                                            outerRadius={outerRadius}
                                            cx="50%" cy="50%"
                                           >

                                    {
                                        diagramData.map((entry, index) => <Cell
                                            fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}/>)
                                    }
                                </Pie>


                        })}

                    <Legend/>


                    </PieChart>
                </ResponsiveContainer>




    </Box>}