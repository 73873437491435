import {LoraNode} from "../../../model/lora-node/LoraNode";
import {useMap} from "react-leaflet";
import React, {useEffect} from "react";
import * as L from "leaflet";
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import {useMaterialOnFireNavigate} from "../../MaterialOnFire/custom-hooks/useMaterialOnFireNavigate";


export const NodeModalMapHandler = (props: { loraNode: LoraNode}) => {


    const map = useMap();
    const {navigate} = useMaterialOnFireNavigate()

    useEffect(() => {
        let marker = L.marker(
            new L.LatLng(props.loraNode.location.lat, props.loraNode.location.lng),
            {
                icon: divIcon({
                    html: renderToStaticMarkup(
                        <div className={`marker`}>
                            <div
                                style={{
                                    color: "white",
                                    transform: "rotate(-45deg)",
                                    textDecorationColor: "white",
                                    zIndex: "300",
                                    width: "75%",
                                    height: "75%",
                                    filter:
                                        "invert(100%) sepia(0%) saturate(7468%) hue-rotate(107deg) brightness(109%) contrast(100%)",
                                }}
                            >

                            </div>
                        </div>
                    ),
                    className: "",
                }),
            }
        );

        marker.addEventListener("click",()=>{
            navigate(``,`map`,`?lat=${props.loraNode.location.lat}&lng=${props.loraNode.location.lng}&zoomLevel=19`)
        })

        map.addLayer(marker);
    }, []);

    return <div></div>


}