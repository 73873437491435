import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {useEffect, useState} from "react";
import {NodePageNodeCard} from "./NodePageNodeCard";
import {useTranslation} from "../../MaterialOnFire/custom-hooks/useTranslation";
import {OrganizedData, useGetOrganizedNodes} from "./useGetOrganizedNodes";
import {useGetDeviceTimeSeries} from "../useGetDeviceTimeSeries";
import {useGetNodePageKPIData} from "./useGetNodePageKPIData";
import {useGetOverviewKPIDisplayMap} from "./useGetOverviewKPIDisplayMap";








export const NodePage = () => {

    const loraNodes = useSelector((state: any) => state["loraNodes"].items as LoraNode[])
    const [organizedNodes, setOrganizedNodes] = useState({})
    const [sortMode, setSortMode] = useState<"none"|"hardwareType"|"group">("none")
    const {t} = useTranslation()

    const cumulativeOrganizedData = useGetOrganizedNodes(loraNodes)
    const kpiOverviewMap = useGetOverviewKPIDisplayMap(loraNodes)
    const [loading, nodeTimeSeriesMap] = useGetNodePageKPIData(cumulativeOrganizedData.hardwareType)

    useEffect(() => {
        setOrganizedNodes(cumulativeOrganizedData[sortMode])
    }, [cumulativeOrganizedData,sortMode]);

    return (
        <Box display={"flex"}  alignItems={"center"} justifyContent={"center"}  flexDirection={"column"} flexGrow={1} overflow={"hidden"} key={"content-wrapper"} >

            <Box minHeight={"5%"} height={"5%"} maxHeight={"5%"} width={"100%"}  >
                <FormControl focused={true}>
                    <FormLabel id="Sort-mode">{t("NodePage.SortMode")}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="Sort-Mode-Button-Group"
                        defaultValue={"none"}

                        >
                        <FormControlLabel value="none" control={<Radio sx={(theme)=>{ return {color: theme.palette.secondary.main,
                                                                            "&.Mui-checked":{
                                                                                color: theme.palette.secondary.main
                            }
                        }
                        }} />} label={t("NodePage.A-Z")} color={"secondary"} onClick={()=> setSortMode("none")} />
                        <FormControlLabel value="hardwareType" control={<Radio sx={(theme)=>{ return {color: theme.palette.secondary.main,
                            "&.Mui-checked":{
                                color: theme.palette.secondary.main
                            }
                        }
                        }}  />} label={t("NodePage.HardwareType")} onClick={()=> setSortMode("hardwareType")}/>
                        <FormControlLabel value="group" control={<Radio sx={(theme)=>{ return {color: theme.palette.secondary.main,
                            "&.Mui-checked":{
                                color: theme.palette.secondary.main
                            }
                        }
                        }} />} label={t("NodePage.Group")} onClick={()=> setSortMode("group")}/>
                    </RadioGroup>
                </FormControl>
            </Box>


            <Box display={"flex"}
                 flexGrow={1}
                 flexWrap={"wrap"}
                 flexDirection={"row"}
                 overflow={"auto"}
                 alignContent={"flex-start"}
                 justifyContent={"flex-start"}
                 maxWidth={"100%"}
                 gap={"0.5rem"}
                 key={"card-wrapper"}
            >

                    {
                        Object.keys(organizedNodes).sort().map((key) => {
                            return[
                                <Box component={Paper} bgcolor={"lightgrey"} key={key}  display={"flex"} width={"100%"} height={"3rem"} justifyContent={"flex-start"} alignItems={"center"} color={"primary"} marginTop={"0.75rem"} >

                                    <Typography align={"center"} variant="h6" noWrap={true}>{key}</Typography>

                                </Box>,
                                //@ts-ignore
                                    organizedNodes[key].loraNode.map((node) => {
                                        return (
                                            <NodePageNodeCard key={node.id} node={node} loading={loading} kpiToDisplay={kpiOverviewMap[node.id]} value={nodeTimeSeriesMap[node.id]?.timeSeries } />
                                        )
                                    })

                            ]


                        })

                    }

            </Box>


        </Box>
    )
}