import React, {useEffect, useRef, useState} from "react";
import {Box, IconButton, Menu, Paper, Skeleton, Typography, Tooltip} from "@mui/material";
import {DiagramParams} from "./Diagram";
import {FilterAlt} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "../../../../MaterialOnFire/custom-hooks/useTranslation";


interface DiagramCardProps extends DiagramParams{
    children?: React.ReactNode;
}
export const DiagramCard = (props: DiagramCardProps) => {

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const [position, setPosition] = useState({
        posX: props.positionProps.posX,
        posY: props.positionProps.posY
    })
    const [delta, setDelta] = useState({x: 0, y: 0})

    const [size, setSize] =
        useState({
            sizeX: props.positionProps.sizeX,
            sizeY: props.positionProps.sizeY
        })


    useEffect(() => {
        console.log("New Position", props.positionProps, "Old Position", position)
        setPosition({
            posX: props.positionProps.posX,
            posY: props.positionProps.posY
        })
        setSize( {sizeX: props.positionProps.sizeX,
            sizeY: props.positionProps.sizeY})
    }, [props.positionProps]);

    const boxRef = useRef<any>();
    const {t} = useTranslation()

    return <Box
        component={Paper}
        ref={boxRef}
        bgcolor={"white"}
        className={"#diagram"} sx={{
        gridRowStart: `${position.posY}`,
        gridRowEnd: `${position.posY + size.sizeY}`,
        gridColumnStart: `${position.posX}`,
        gridColumnEnd: `${position.posX + size.sizeX}`,
        width: props.width,
        height: props.height}}
        onContextMenu={ (e:any) => {
            e.preventDefault();

            setContextMenu(contextMenu === null ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null);
        }
    }
        draggable={true}
        onDragStart={(e:any) => {

            const boxPosition = boxRef.current.getBoundingClientRect()
            console.log("Delta",{x: boxPosition.x - e.clientX, y: boxPosition.y - e.clientY})
            setDelta({x: boxPosition.x - e.clientX, y: boxPosition.y - e.clientY})
            props.setDragStatus(props.diagramProps.name, true, props.origin, props.diagramProps,{
                mouseX: e.clientX,
                mouseY: e.clientY,
                diagramX: boxPosition.x,
                diagramY: boxPosition.y
            })
        }}
        onDragEnd={(e:any) => {
            props.onDragEnd(e.clientX + delta.x, e.clientY + delta.y)
        }}
        onDrag={(e:any) => {
            props.onDrag(e)
        }}
        width={"100%"}
        zIndex={999}
        display={"flex"}
        alignContent={"center"}
        justifyItems={"center"}
        elevation={3}
        position={"relative"}


    >
        <Menu open={contextMenu !== null}
              onClose={() => setContextMenu(null)}
              anchorReference="anchorPosition"
              anchorPosition={
                  contextMenu !== null
                      ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                      : undefined
              }
        >
            <MenuItem onClick={()=>{
                setContextMenu(null)
                props.deleteFromDashboard()
            }}> <DeleteIcon/> {t("DiagramDefinition.Diagram.DeleteFromDashboard")} </MenuItem>

        </Menu>

        { props.resizable ?  <div className={"diagram-resizer"} draggable={true} onDrag={ (e)=>{

            boxRef.current.style.width = e.clientX - boxRef.current.getBoundingClientRect().left + "px"
            boxRef.current.style.height = e.clientY - boxRef.current.getBoundingClientRect().top + "px"


            e.preventDefault()
            e.stopPropagation()
        }}
                                  onDragEnd={(e) => {
                                      //Warum + 10? ganz einfach die obere linke ecke der box liegt genau auf der grenze des css grids
                                      //bei der Kalkulation der neuen Position muss das berücksichtigt werden, da ansonten die box eine zelle nach oben und links gezogen wirdxs
                                      props.onDragEnd(boxRef.current.getBoundingClientRect().left + 10, boxRef.current.getBoundingClientRect().top + 10, e.clientX, e.clientY)

                                      boxRef.current.style.width = undefined;
                                      boxRef.current.style.height = undefined;
                                      e.stopPropagation()

                                  }

                                  }

        />  :null}

        <Box sx={{
            width: "99%",
            height: "99%",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            position: "relative",



        }} bgcolor={"white"} >

                <Box justifyItems={"center"} justifyContent={"center"} height={"5%"} paddingBottom={"2rem"} width={"100%"} margin={"0.2rem"} overflow={"hidden"}>
                    <Tooltip title={props.diagramProps.name}>
                    <Typography  noWrap={true} align={"center"} maxWidth={"100%"} variant={"h6"} > {props.diagramProps.name}</Typography>
                    </Tooltip>
                </Box>


                { props.origin === "dashboard" || props.origin === "config" ?props.children :   <Skeleton variant="rounded" animation={"pulse"} width={"100%"} height={"95%"} sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    Diagramm auf das Dashboard ziehen, um den Inhalt zu sehen</Skeleton> //TODO: our translation doesn't work within the skeleten component
                     }

        </Box>

    </Box>

}