import {Box, IconButton, Modal, Paper, Typography} from "@mui/material";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import "./NodeModal.css"
import {NodeDetails} from "../NodeDetails";
import {MessageTimeline} from "../MessageTimeline";
import {MapTileProvider} from "../../map/MapTileProvider";
import React from "react";
import {MapContainer} from "react-leaflet";
import {NodeModalMapHandler} from "./NodeModalMapHandler";
import {Close} from "@mui/icons-material";


export interface NodeModalProps {
    loraNode: LoraNode
    open: boolean;
    onClose: () => void;
}

export const NodeModal = (props: NodeModalProps) => {



    return  props.open ? <Modal key="Modal" id="NodeModal" open={props.open} onClose={props.onClose}>
        <Box sx={(theme) => {
            return {
                backgroundColor: theme.palette.background.default,
            }
        }} key={"Wrapper"} id={"NodeModalWrapper"} className={"NodeModalWrapper"}>

            <IconButton sx={{position : "absolute" , top : 0, right:0}} onClick={( event )=>{props.onClose() ; event.stopPropagation()}}>
                <Close onClick={()=>{props.onClose()}}/>
            </IconButton>

            <Box key={"Header"} id={"NodeModalHeader"} className={"NodeModalHeader"}>

                <Typography align={"center"} width={"100%"} variant={"h3"}>{props.loraNode.name}</Typography>

            </Box>

            <Box className={"NodeModalContentContainer"}>
                <Box key={"Details"} className={"NodeModalContentContainerHalfSize"}>
                    <Paper sx={{height: "99%", width: "99%"}} elevation={3}>
                        <NodeDetails key={"NodeDetails"} id={props.loraNode.id}/>
                    </Paper>
                </Box>
                <Box key={"Map"} className={"NodeModalContentContainerHalfSize"}>
                    <Paper sx={{height: "99%", width: "99%"}} elevation={3}>
                        <MapContainer
                            center={[props.loraNode.location.lat , props.loraNode.location.lng]}
                            zoom={19}
                            maxZoom={25}
                            scrollWheelZoom={true}
                            className="markercluster-map"
                            style={{ height: "100%", width: "100%" }}
                        >
                            <MapTileProvider/>
                            <NodeModalMapHandler loraNode={props.loraNode}/>
                        </MapContainer>
                    </Paper>
                </Box>
            </Box>


            <Box key={"Timeline"} className={"NodeModalContentContainer"}>
                <Paper sx={{height: "99%", width: "99%", overflow: "auto"}} elevation={3}>
                    <MessageTimeline key={"MessageTimeline"} selectedNode={props.loraNode}/>
                </Paper>
            </Box>


        </Box>
    </Modal> : null ;


}