import React, {useEffect, useRef, useState} from "react";
import {useGetUpdateCoords} from "./DiagramHelper/useGetUpdateCoords";
import {useGetDnDHandleFunctions} from "./DiagramHelper/useGetDnDHandleFunctions";
import {Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, TextField} from "@mui/material";
import {Diagram} from "./Diagrams/Diagram";
import {DnDOverlay} from "./DiagramHelper/DnDOverlay";
import {useGetCSSGridTemplateColumns} from "./DiagramHelper/useGetCSSGridTemplateColumns";
import {DashboardDiagramConfig, DashboardProperties, DiagramProperties} from "./types/type";
import {MaintenanceViewProps} from "../../../MaterialOnFire/GenericUIFields/MaintenanceView";
import {GenericActionBar} from "../../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {DiagramPreview} from "./DiagramHelper/DiagramPreview/DiagramPreview";
import {useSelector} from "react-redux";

interface DiagramContainerProps extends MaintenanceViewProps {
    width: string | number,
    height: string | number,
    columns: number
}

export interface DragStatus {
    dashboardName: string,
    dragging: boolean,
    origin: "dashboard" | "preview",
    diagramProperties: DiagramProperties | null,
    position: { mouseX: number, mouseY: number, diagramX: number, diagramY: number }

}

export const DiagramContainer = (props: DiagramContainerProps) => {

    //State definitions

    const [dashboard, setDashboard] = useState<DashboardProperties>(props.data)
    const [posDisplay, setPosDisplay] = useState({x: 0, y: 0})
    const [dragStatus, setDragStatus] = useState<DragStatus>({
        dashboardName: "",
        dragging: false,
        origin: "dashboard",
        diagramProperties: null,
        position: {mouseX: 0, mouseY: 0, diagramX: 0, diagramY: 0}
    })
    //Ref definitions
    const ref = useRef<any>();
    const columnRef = useRef(posDisplay)


    //Redux Definitions
    const reduxDiagramDefinitions: DiagramProperties[] = useSelector((state: any) => state["DiagramDefinition"].items as DiagramProperties[])


    //Effects
    useEffect(() => {

        //updating the DiagramDefinitions based on the cached firebase data
        const newDashboard = {...props.data} as DashboardProperties

        const updatedDiagramDefinitions = newDashboard.diagrams?.map((diagram) => {
            const diagramDefinition = reduxDiagramDefinitions.find((definition) => definition.id === diagram.DiagramDefinition.id)
            return {...diagram, DiagramDefinition: diagramDefinition || diagram.DiagramDefinition}
        })
        setDashboard({...props.data, diagrams: updatedDiagramDefinitions} as DashboardProperties)
    }, [props.data?.id])

    //Custom hooks
    const updateCoords = useGetUpdateCoords(dashboard, (data: DashboardProperties) => setDashboard(data))
    const {setDragStatusCallback, onDrag, onDragEnd} = useGetDnDHandleFunctions(setDragStatus,
        ref,
        dragStatus,
        props.columns,
        columnRef,
        setPosDisplay,
        updateCoords,
        (diagram) => {
            const newDiagrams = dashboard.diagrams ? [...dashboard.diagrams, diagram] : [diagram];
            setDashboard(
                {...dashboard, diagrams:newDiagrams}
            )
        })
    const rowAndColumnTemplate = useGetCSSGridTemplateColumns(props.columns)
    return (
        <Box key="DashboardContainer" display={"flex"} position="relative" flexDirection={"column"} width={props.width}
             height={props.height} maxHeight={"100%"} justifyContent={"flex-start"} alignItems={"flex-start"} overflow={"hidden"}
        >
            <DiagramPreview dashboard={dashboard} setDragEnabled={setDragStatusCallback} onDrag={onDrag}
                            onDragEnd={onDragEnd}
                            dashboardIdList={dashboard.diagrams?.map(diagram => diagram.DiagramDefinition.id) || []}/>


            <Box display={"flex"} position={"absolute"} left={0} top={0} zIndex={9999} width={"100%"} justifyContent={"center"}>
                <TextField key={`${dashboard?.id}-name`} variant={"standard"} value={dashboard.name} onChange={(e)=>{
                    setDashboard({...dashboard, name:e.target.value})
                }} /> </Box>
            <Box width={"100%"} display={"flex"} flexDirection={"row"}>
                <Grid container width={"50%"} >

                <GenericActionBar state={dashboard} actions={props.actions} threshold={999}/>

                </Grid>

            </Box>
            <Box key={`${dashboard?.id}-DiagramSpace`} display="flex" width={"100%"} maxHeight={"100%"} flexGrow={1} overflow={"hidden"}>

                <Box ref={ref}
                     display={"grid"}
                     gridTemplateColumns={rowAndColumnTemplate}
                     gridTemplateRows={rowAndColumnTemplate}
                     width={"100%"}
                     height={"100%"}
                     bgcolor={"white"}
                >
                    {dashboard.diagrams?.map((props, index) => {
                        return <Diagram
                            resizable={true}
                            key={index + "-" + props.posX + "-" + props.posY}
                            positionProps={{
                                posX: parseInt(props.posX + ""),
                                posY: parseInt(props.posY + ""),
                                sizeX: parseInt(props.sizeX + ""),
                                sizeY: parseInt(props.sizeY + "")
                            }}
                            diagramProps={props.DiagramDefinition}
                            origin={"dashboard"}
                            setDragStatus={setDragStatusCallback}
                            onDragEnd={onDragEnd}
                            onDrag={onDrag}
                            deleteFromDashboard={() => {
                                setDashboard({
                                    ...dashboard,
                                    diagrams: dashboard.diagrams.filter(diagram => diagram.DiagramDefinition.id !== props.DiagramDefinition.id)
                                })
                            }}
                        />
                    })
                    }

                    {<DnDOverlay key="overlay" dragStatus={dragStatus} columns={props.columns}
                                 posDisplay={posDisplay}/>}
                </Box>
            </Box>
        </Box>

    );
}