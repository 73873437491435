import {OrganizedData, useGetOrganizedNodes} from "./useGetOrganizedNodes";
import {useEffect, useState} from "react";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {DiagramProperties, TimeSeriesEntry} from "../../custom-hooks/uiEnhancers/Dashboards/types/type";
import {fetchLastDataFromBackend} from "../../custom-hooks/uiEnhancers/Dashboards/DiagramHelper/useGetDiagramData";

export interface NodeTimeSeriesMap {
    [key: string]: {
        timeSeries: TimeSeriesEntry
    }
}


export const useGetNodePageKPIData = ( organizedDataByHardwareType: OrganizedData ) :[boolean, NodeTimeSeriesMap] => {

    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)
    const [nodeTimeSeriesMap, setNodeTimeSeriesMap] = useState<NodeTimeSeriesMap>({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        if(!backendConfig){
            return
        }

        const { unknown, ...hardwareTypes }  = organizedDataByHardwareType

        const hardwareTypeIds = Object.keys(hardwareTypes)
        setLoading(true)

        const promises = hardwareTypeIds.map( async (hardwareTypeId) => {
            const diagramProps: DiagramProperties = {
                name: "Diagram",
                diagramProperties: {
                    duration: 36,
                    durationUnit: "h",
                    startTime: "now()",
                    diagramType: "line",
                    aggregationInterval: "1h",
                    aggregationStrategy: "mean",
                },
                id: "1",
                influxQueryProperties: {
                    nodeIds: hardwareTypes[hardwareTypeId].loraNode.map(node => node.id),
                    values: [".*"],
                    influxBucketName: hardwareTypeId || ""
                }
            }

            return  fetchLastDataFromBackend(backendConfig, diagramProps, hardwareTypeId)
        })

        Promise.all(promises).then( (results) => {
            const newMap:NodeTimeSeriesMap = {}
            results.map( (result) => {
                    return result.timeSeries
            }).flat(1).forEach( (timeSeriesEntry) => {
                const { nodeId } = timeSeriesEntry
                const node = newMap[nodeId] || {timeSeries: []}

                const recentTime = ( node.timeSeries.time > timeSeriesEntry.time ) ? node.timeSeries.time : timeSeriesEntry.time
                node.timeSeries=  {  ...node.timeSeries, ...timeSeriesEntry , time: recentTime }
                newMap[nodeId] = node
            })
                setNodeTimeSeriesMap(newMap)
                setLoading(false)
        }


        ).catch( (error) => {
            console.error(error)
            setLoading(false)
            alert("Error fetching data")
        })


    }, [organizedDataByHardwareType,backendConfig]);


    return [loading,nodeTimeSeriesMap ]

}