

export const BACKEND_CONFIG_PARAMETER_NAME = "Backend_Settings"
export type backendRemoteConfig = {
    url : string,
    tenantId: string,
    influxDBModelVersion:string
}


export const ALERT_SETTINGS_PARAMETER_NAME = "Alert_Settings";

export type alertSettings = {
    AllertPollRate: number
    DeviceOfflineDetectionTimeHours: number
}


export const HEAT_MAP_CONFIG_PARAMETER_NAME = "HeatMap_Settings";

export type heatMapSettings = {
    opacity: number,
    goodRssi: number,
    badRssi: number,
}